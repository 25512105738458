<template>
  <div class="activity_share">
    <div class="activity_share_title">活动分享</div>
    <div ref="map" :class="[fullScreen ? 'full-screen' : 'normal-screen', 'map-wrap']">
      <div class="extra">
        <img
          class="nav_img"
          src="@/assets/full_screen.png"
          alt=""
          width="25"
          height="25"
          @click="fullScreen = !fullScreen"
          v-if="!fullScreen"
        />
        <img
          class="nav_img"
          src="@/assets/cancel_screen.png"
          alt=""
          @click="fullScreen = !fullScreen"
          v-if="fullScreen"
        />
      </div>
    </div>
    <div class="activity_info">
      <div class="info_title padding_t padding_lr">{{ view.activity && view.activity.activityName }}</div>
      <div class="detail_intro padding_lr">
        <span></span>
        <span></span>
        <span>{{
            activityInfo.isPublic == null ? "未知" : isPublic[activityInfo.isPublic]
          }}</span>
      </div>
      <div class="collect padding_lr">
        <span>开始时间：{{ activityInfo.startTime }}</span>
        <span>发起人：{{ activityInfo.owner && activityInfo.owner.userName }}</span>
      </div>
      <div class="collect padding_lr padding_b border_b">
        <span>结束时间：{{ activityInfo.endTime }}</span>
      </div>

      <div class="info_title padding_t padding_lr">研学活动信息</div>
      <div class="info_base padding_lr">
        <van-icon name="user-o" color="#1890ff" /><span
            >所属机构: {{
              (activityInfo.organization && activityInfo.organization.orgName) || "未知"
            }}</span
          >
      </div>
      <div class="info_base padding_lr">
        <van-icon name="notes-o" color="#1890ff" /><span
            >参与人员:
              <van-tag
                color="#7232dd"
                plain
                v-for="(item, index) in activityInfo.activityPeers"
                :key="index"
                >{{ item.userName }}</van-tag
              >
            </span
          >
      </div>
      <div class="info_base border_b padding_b padding_lr">
        <van-icon name="orders-o" color="#1890ff" /><span
            >活动简介: {{
              (activityInfo.activityDetail ? getSimpleText(activityInfo.activityDetail) : "--")
            }}</span
          >
      </div>

      <div class="info_title padding_t padding_lr">研学活动路线</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchLines == null">暂无路线</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchLines">
        <van-tag
            class="margin_r_5 margin_b_3 c-h"
            color="#39ccc4"
            plain
            v-for="(item, index) in lines"
            :key="index"
            @click="lineGpx(item.lineGpx)"
            >{{ item && item.lineName }}</van-tag
          >
      </div>

      <div class="info_title padding_t padding_lr">研学活动点</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchPoints == null">暂无研学点</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchPoints">
        <van-tag
            class="margin_r_5 margin_b_3 c-h"
            color="#4fb5ff"
            plain
            v-for="(item, index) in points"
            :key="index"
            @click="getPanorama(item.point360)"
            >{{ item.pointName }}</van-tag
          >
      </div>
      
      <div class="info_title padding_t padding_lr">研学活动课程</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchCourse == null">暂无活动课程</div>
      <div class="padding_lr margin_t_3 padding_b border_b" v-if="activityInfo.researchCourse">
        <img src="/static/images/model/epub.jpg" alt="" class="course_img"/>
      </div>
      <!-- <div class="padding_b border_b"></div> -->
      
      <div class="info_title padding_t padding_lr">研学活动资源</div>
      <div class="margin_t_3 padding_b">
        <van-tabs v-model="active" color="#2a8ffa" @click="onClick">
          <van-tab title="全部" name="all"></van-tab>
          <!-- 分享活动仅支持查看个人数据 -->
          <!-- <van-tab
            :title="activityInfo.owner.userName"
            :name="activityInfo.owner.userId"
            v-if="activityInfo.owner"
          ></van-tab>
          <template
            v-if="
              activityInfo.activityParticipants &&
                activityInfo.activityParticipants.length
            "
          >
            <van-tab
              v-for="(item, index) in activityInfo.activityParticipants"
              :title="item.userName"
              :name="item.userId"
              :key="index"
            ></van-tab>
          </template> -->
        </van-tabs>
        <div id="totalItem" class="total_item" v-if="resources.length">
          <div id="gpx" class="info_title padding_t padding_lr margin_t_3 border_t">轨迹</div>
          <div class="padding" v-if="resourceType.gpxData.length == 0">
            暂无轨迹
          </div>
          
          <div class="padding" v-if="resourceType.gpxData.length">
            <van-tag
              plain
              color="#7232dd"
              class="margin_r_5 margin_b_3 c-h"
              v-for="(item, index) in resourceType.gpxData"
              :key="index"
              @click="lineGpx(item.context)"
              >{{ `航迹_${item.userId}` }}</van-tag
            >
          </div>
          <div id="image" class="info_title padding_t padding_lr margin_t_3 border_t">图片</div>
          <div class="padding" v-if="resourceType.imageData.length == 0">
            暂无图片
          </div>
          <div
            class="total_top"
            v-for="item in resourceType.imageData"
            :key="item.id"
          >
            <div class="total_img">
              <van-image :src="item.context" lazy-load class="img_normal">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error>
                  <img src="@/assets/no_image.png" class="img_error" />
                </template>
              </van-image>
            </div>
          </div>

          <div id="video" class="info_title padding_t padding_lr margin_t_3 border_t">视频</div>
          <div class="padding" v-if="resourceType.videoData.length == 0">
            暂无视频
          </div>
          <div
            class="total_video"
            v-for="item in resourceType.videoData"
            :key="item.id"
          >
            <div>
              <video class="video" controls>
                <source :src="item.context" />
              </video>
            </div>
          </div>

          <div id="text" class="info_title padding_t padding_lr margin_t_3 border_t">文本</div>
          <div class="padding" v-if="resourceType.textData.length == 0">
            暂无本文
          </div>
          <div
            class="total_text padding_t"
            v-for="(item, index) in resourceType.textData"
            :key="item.id"
          >
            {{ `${index + 1}、  ${item.context}` }}
          </div>
        </div>
        <div class="text-c padding" v-else>
          暂无数据
        </div>
      </div>


    </div>
    <PanoramaView :modal="modal" />
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
// 注册时可以配置额外的选项
Vue.use(Lazyload);
import '@/style/rem.less'
import { mapActions } from 'vuex'
import { util } from "@/libs"
import { getShareActivity } from "@/libs/api/activity.js"

import { cesiumInit, drawTool } from "@/mixin/cesium.js";
import PanoramaView from "@/views/dataV/index/panoramaView.vue";
export default {
  mixins: [cesiumInit, drawTool],
  components: { PanoramaView },
  data() {
    return {
      getSimpleText: util.getSimpleText(),
      fullScreen: false,
      isPublic: { true: "公开", false: "非公开" },
      view: {},
      active: "all",
      resources: [],
      modal: {
        show: false,
        data: {}
      }
    }
  },
  computed: {
    activityInfo() {
      let activity = this.view.activity || {}
      return activity
    },
    lines() {
      let line = this.view.line
      let lines = []
      if(line) {
        let values = Object.values(line)
        values.map((val, index) => {
          if (val) {
            lines.push(val)
          } else {
            lines.splice(index, 1)
          }
        })
      }
      return lines
    },
    points() {
      let points = []
      let point = this.view.point
      if(point) {
        let values = Object.values(point)
        values.map((val, index) => {
          if (val) {
            points.push(val)
          } else {
            points.splice(index, 1)
          }
        })
        return points

      }
    },
    resourceType() {
      let resources = this.resources || [],
        imageData = [],
        videoData = [],
        textData = [],
        gpxData = [];
      if(resources) {
        imageData = resources.filter(
          (item) => item.resourceType == "pan/image"
        );
        videoData = resources.filter(
          (item) => item.resourceType == "pan/video"
        );
        textData = resources.filter(
          (item) => item.resourceType == "pan/text"
        );
        gpxData = resources.filter(
          (item) => item.resourceType == "pan/gpx"
        );

      }
      return {
        resources,
        imageData,
        videoData,
        textData,
        gpxData,
      }
    }
  },
  methods: {
    // 获取全景图
    getPanorama(id) {
      if(id) {
        console.log(this.view)
        let data = this.view.qjt[id]
        if(data) {
          Object.assign(this.modal, { show: true, data })
        } else {
          this.$toast("暂无全景图！")
        }
      } else {
        this.$toast("暂无全景图！")
      }
    },

    onClick(tab) {
      this.active = tab
      if(this.active !== 'all') {
        let resource = [].concat(...Object.values(this.view.resources))
        this.resources = resource.filter(item => item.userId == this.active)
      } else {
        this.resources = [].concat(...Object.values(this.view.resources))
      }
    },
    // 加载研学路线的航迹
    lineGpx(lineGpx) {
      if(lineGpx)
        this.gpxLayer(lineGpx)
    },
    // 加载研学点
    pointLayer() {
      if(this.points.length) {
        this.points.map(data => {
          if(data) {
            let point = {
              ...data,
              id: data.researchPointId,
              name: data.pointName,
              lng: data.pointCoord.lon,
              lat: data.pointCoord.lat,
              ele: data.pointCoord.ele,
              width: 30,
              height: 30,
              image: "leida.svg",
            }
            this.addPoint(point)
          }
          
        })
      }
    },
    // 加载研学线
    lineLayer() {
      if(this.lines.length) {
        this.lines.map(item => {
          if(item) {
            this.addPoint({
              ...item,
              id: item.researchLineId,
              name: item.lineName,
              lng: item.lineCenter.lon,
              lat: item.lineCenter.lat,
              ele: 1000,
              width: 30,
              height: 30,
              image: "line.svg",
            })
            if(item.lineGpx !== "" || item.lineGpx != null) {
              this.lineGpx(item.lineGpx)
            }
          }
        })
      }
    },
    getData() {
      getShareActivity({ base: this.$route.params.base }).then(({ code, data }) => {
        if(code == "HA0200") {
          this.view = data
          this.resources = [].concat(...Object.values(this.view.resources))
          if(this.points) {
            this.pointLayer()
          }
          if(this.lines) {
            this.lineLayer()
          }
        } else {
          this.view = {}
        }
      })
    }
  },
  mounted() {
    this.cesiumMap(this.$refs.map);
    this.drawInit();
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width:720px) {
  .activity_share {
    width: 100%;
    height: 100%;
    position: fixed;
    .activity_share_title {
      width: 100%;
      height: 1.6rem;
      line-height: 1.6rem;
      font-size: .7rem;
      text-align: center;
      color: #fff;
      background: #52acf3;
    }
    .map-wrap {
      width: 100%;
      height: calc(100% - 1.6rem);
      .extra {
        display: none;
      }
    }
    .activity_info {
      // display: none;
      position: absolute;
      bottom: .2rem;
      right: 0;
      width: 14rem;
      height: calc(100% - 2rem);
      z-index: 999;
      background: rgba(29, 28, 28, 0.8);
      color: #fff;
      overflow-y: scroll;
      font-size: 14px;
      padding-left: 10px;

      .padding {
        padding: 0.4rem;
      }
      .padding_lr {
        padding-left: .4rem;
        padding-right: .4rem;
      }
      .padding_l {
        padding-left: .4rem;
      }
      .padding_r {
        padding-right: .4rem;
      }
      .padding_t {
        padding-top: .4rem;
      }
      .padding_b {
        padding-bottom: .4rem;
      }
      .border_b {
        border-bottom: solid .4rem rgba(10, 9, 9, 0.4);
      }
      .border_t {
        border-top: solid .4rem rgba(10, 9, 9, 0.4);
      }
      .font_8 {
        font-size: .4rem;
      }
      .info_title {
        font-size: .5rem;
        color: #fff;
      }
      .detail_intro {
        display: flex;
        align-items: center;
        margin: 0.2rem 0;
        span {
          padding: 0.16rem 0.4rem;
          font-size: .4rem;
          background: rgb(224, 247, 245);
          color: rgb(57, 204, 196);
          border-radius: .3rem;
          margin-right: .3rem;
          &:nth-of-type(2) {
            background: rgba(95, 197, 255, 0.2);
            color: rgb(79, 181, 255);
          }
          &:nth-of-type(3) {
            background: rgba(255, 186, 133, 0.2);
            color: rgb(255, 161, 89);
          }
          &:nth-of-type(4) {
            background: rgba(114, 162, 255, 0.2);
            color: rgb(78, 138, 255);
          }
        }
      }
      .collect {
        margin-top: 0.2rem;
        span {
          margin-right: .6rem;
        }
      }
      .margin_t_3 {
        margin-top: .3rem;
      }
      .margin_b_3 {
        margin-bottom: .3rem;
      }
      .margin_r_5 {
        margin-right: .5rem;
      }
      .margin_r {
        margin-right: 1rem;
      }
      .info_base {
        margin-top: .4rem;
        .van-icon {
          margin-right: .3rem;
        }
        span {
          margin-right: .3rem;
          word-break: break-all;
        }
      }
      .course_img {
        width: 5rem;
        height: 6rem;
        margin-right: .3rem;
      }

      .total_top {
          margin-bottom: .4rem;
          .total_img {
            margin-top: .4rem;
            border-radius: .4rem;
            background: transparent;
            height: 100%;
            padding-left: .4rem;
            padding-right: .4rem;
            .img_normal {
              display: flex;
              align-items: center;
              min-height: 100px;
              background: transparent;
            }
          }
        }

      .total_video {
          margin-top: .3rem;
          div {
            width: 100%;
            height: 10rem;
            margin: .4rem 0;
            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      .total_text {
          text-indent: 2em;
        }

    }
  }
}

/* 以上是正常布局（电脑模式）  下面是媒介查询，当宽度低于720px 是采用手机模式布局*/
@media screen and (max-width:720px) {
  .activity_share {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  .activity_share_title {
    width: 100%;
    height: 3rem;
    color: #fff;
    line-height: 3rem;
    text-align: center;
    font-size: 1rem;
    z-index: 21;
    position: fixed;
    top: 0;
    background-color: #52acf3;
  }
  .full-screen {
    height: calc(100% - 3rem);
  }
  .normal-screen {
    height: 12rem;
  }
  .map-wrap {
    position: relative;
    margin-top: 3rem;
    .extra {
      display: block;
      z-index: 1;
      position: absolute;
      top: .4rem;
      right: .4rem;
    }
    .tool-wrap {
      z-index: 9;
      height: 90%;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .tool-button {
        padding: 2px 5px;
        background: #fff;
        color: #333;
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 13px;
        &:hover {
          background: #5cadff;
          color: #fff;
        }
      }
    }
  }
  .activity_info {
    display: block;
    position: static;
    width: 100%;
    height: calc(100% - 15rem);
    overflow-y: scroll;
    color: #a2a2a2;
    background: #fff;
    // padding-left: 10px;
    .padding {
      padding: 0.8rem;
    }
    .padding_lr {
      padding-left: .8rem;
      padding-right: .8rem;
    }
    .padding_l {
      padding-left: .8rem;
    }
    .padding_r {
      padding-right: .8rem;
    }
    .padding_t {
      padding-top: .8rem;
    }
    .padding_b {
      padding-bottom: .8rem;
    }
    .border_b {
      border-bottom: solid .8rem #f5f5f5;
    }
    .border_t {
      border-top: solid .8rem #f5f5f5;
    }
    .font_8 {
      font-size: .8rem;
    }
    .info_title {
      font-size: 1rem;
      color: #2c2525;
    }
    .detail_intro {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      span {
        padding: 0.2rem 0.7rem;
        font-size: .8rem;
        background: rgb(224, 247, 245);
        color: rgb(57, 204, 196);
        border-radius: .3rem;
        margin-right: .4rem;
        &:nth-of-type(2) {
          background: rgba(95, 197, 255, 0.2);
          color: rgb(79, 181, 255);
        }
        &:nth-of-type(3) {
          background: rgba(255, 186, 133, 0.2);
          color: rgb(255, 161, 89);
        }
        &:nth-of-type(4) {
          background: rgba(114, 162, 255, 0.2);
          color: rgb(78, 138, 255);
        }
      }
    }
    .collect {
      margin-top: 0.3rem;
      span {
        margin-right: 1rem;
      }
    }
    .margin_t_3 {
      margin-top: .3rem;
    }
    .margin_b_3 {
      margin-bottom: .3rem;
    }
    .margin_r_5 {
      margin-right: .5rem;
    }
    .margin_r {
      margin-right: 1rem;
    }
    .info_base {
      margin-top: .4rem;
      .van-icon {
        margin-right: .3rem;
      }
      span {
        margin-right: .3rem;
        word-break: break-all;
      }
    }
    .course_img {
      width: 5rem;
      height: 6rem;
      margin-right: .3rem;
    }

    .total_top {
        margin-bottom: .8rem;
        .total_img {
          margin-top: .8rem;
          border-radius: .8rem;
          background: #fff;
          height: 100%;
          padding-left: .8rem;
          padding-right: .8rem;
          .img_normal {
            display: flex;
            align-items: center;
            min-height: 100px;
            background: #fff;
          }
        }
      }

    .total_video {
        margin-top: .3rem;
        div {
          width: 100%;
          height: 10rem;
          margin: .4rem 0;
          video {
            width: 100%;
            height: 100%;
          }
        }
      }
    .total_text {
        text-indent: 2em;
      }
  }
}
}
</style>
<style lang="less">
@media screen and (min-width:720px) {
  .activity_share {
    .van-tabs__nav {
      background-color: rgba(33, 37, 39, 0.6);
    }
    .van-tab {
      color: #efefef;
    }
    .van-tab--active {
      color: #fff
    }
    .van-image__error, .van-image__loading {
      background-color: rgba(33, 37, 39, 0.6);
    }
  }

}
</style>